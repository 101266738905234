import { useQuery } from "react-query";
import {
  doc,
  addDoc,
  collection,
  query,
  orderBy,
  where,
  getDoc,
  getDocs,
  updateDoc,
  writeBatch,
} from "firebase/firestore";
import { createQuery, format, db } from "../providers/database";
import { collectionNames } from "./dictionary/collectionNames";
import { Timestamp } from "firebase/firestore";
import { sortBy } from "lodash";

export function useAllNotificationsForAdmin(numberOfDays) {
  const days = numberOfDays > 0 ? numberOfDays : 1000;
  const today = Timestamp.now();
  const timeFrame = new Date();
  timeFrame.setDate(today.toDate().getDate() - days);
  const timeFrameTimestamp = Timestamp.fromDate(timeFrame);

  return useQuery(
    ["allNotificationsForAdmins", { numberOfDays }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.notifications),
        where("createdAt", ">", timeFrameTimestamp),
        orderBy("createdAt", "desc"),
      );
    }),
  );
}

export function useUserNotificationIds(userId) {
  return useQuery(
    ["allUserNotificationIds", { userId }],
    createQuery(() => {
      return query(
        collection(db, collectionNames.userNotifications),
        where("userId", "==", userId),
        orderBy("createdAt", "desc"),
      );
    }),
    { enabled: !!userId },
  );
}

export function getNotificationData(notificationId) {
  return getDoc(doc(db, collectionNames.notifications, notificationId)).then(
    format,
  );
}

export async function updateAllIsRead(userId) {
  const q = query(
    collection(db, collectionNames.userNotifications),
    where("userId", "==", userId),
  );
  const querySnapshot = await getDocs(q);
  const batch = writeBatch(db);
  querySnapshot.forEach((doc) => {
    const data = doc.data();
    if (!data.isRead) {
      const updatedData = { isRead: true, readAt: Timestamp.now() };
      batch.update(doc.ref, updatedData);
    }
  });
  await batch.commit();
}



export async function deleteAllNotifications(userId) {
  const q = query(
    collection(db, collectionNames.userNotifications),
    where("userId", "==", userId)
  );

  const querySnapshot = await getDocs(q);
  const batch = writeBatch(db);

  querySnapshot.forEach((doc) => {
    batch.delete(doc.ref);
  });

  await batch.commit();
}


export async function updateIsRead(notificationId, userId) {
  const q = query(
    collection(db, collectionNames.userNotifications),
    where("notificationId", "==", notificationId),
    where("userId", "==", userId),
  );
  const querySnapshot = await getDocs(q);
  if (querySnapshot.size === 1) {
    const doc = querySnapshot.docs[0];
    const data = { isRead: true, readAt: Timestamp.now() };
    updateDoc(doc.ref, data);
  }
}

export function createNotification(data) {
  const {
    group,
    title,
    body,
    createdBy,
    isPushNotification,
    url,
    recipientId,
    employerId,
  } = data;
  return addDoc(collection(db, collectionNames.notifications), {
    group: group ? group : "personal",
    title: title,
    body: body,
    createdBy: createdBy,
    createdAt: Timestamp.now(),
    isPushNotification: isPushNotification,
    url: url ? url : "",
    sentTo: recipientId ? recipientId : "",
    employerId: employerId ? employerId : "",
  });
}

export function useAllUnreadNotifications(userId, isRead, employerId) {
  return useQuery(
    ["allUnreadNotifications", { userId, isRead }],
    createQuery(() => {
      if (employerId) {
        return query(
          collection(db, collectionNames.userNotifications),
          where("employerId", "==", employerId),
          orderBy("createdAt", "desc"),
        );
      } else {
        return query(
          collection(db, collectionNames.userNotifications),
          where("userId", "==", userId),
          where("isRead", "==", isRead),
          orderBy("createdAt", "desc"),
        );
      }
    }),
    { enabled: !!userId },
  );
}
