import {
  customDataRouteObject,
  customRoute,
} from "../../dataTypes/ReactRouter";
import { ErrorBoundary } from "react-error-boundary";
import IndexPage from "../../pages";
import NotFoundPage from "../../pages/404";
import { sharedChildren } from "../sharedRoutes";
import { primaryPublicChildren, primaryPublicChildren_newui } from "../publicRoutes";
import EmailVerificationPage from "../../pages/shared/EmailVerificationPage";
import Fallback from "../../components/fallback/Fallback";
import SeekerCompaniesPage from "../../pages/seekerPages/CompaniesPage";
import SeekerJobsPage from "../../pages/seekerPages/JobsPage";
import SeekerViewCompanyDetails from "../../pages/seekerPages/CompanyDetails";
import SeekerListingDetails from "../../pages/seekerPages/JobListingDetails";
import SeekerApplicationsPage from "../../pages/seekerPages/ApplicationsPage";
import SeekerAcademyPage from "../../pages/seekerPages/SeekerAcademyPage";
import SeekerApplicationsDetails from "../../pages/seekerPages/ApplicationDetailsPage";
import SeekerOnboardingPage from "../../pages/seekerPages/SeekerOnboardingPage";
import ApplicationProvider from "../../providers/ApplicationProvider";
import OtherPositions from "../../pages/seekerPages/OtherPositions";
import SeekerPreScreenPage from "../../pages/seekerPages/SeekerPreScreenPage";
import MissionComplete from "../../pages/seekerPages/MissionComplete";
import SeekerOffersPage from "../../pages/seekerPages/OffersPage";
import SeekerMultifactorEnrollmentPage from "../../pages/seekerPages/SeekerMultifactorEnrollmentPage";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";
import SeekerRegistrationIndex from "../../components/seeker/details/registration/SeekerRegistrationIndex";
import SeekerRegistrationIndexNew from "../../components/seeker/details/New_UI/registration/SeekerRegistrationIndexNew";
import Jobs from "../../components/seeker/details/New_UI/Components/Jobs";
import { manageConfiguration } from "../../data/configuration";
import Companies_newUI from "../../components/seeker/details/New_UI/Companies/Companies_newUI";
import { sharedChildren_newUi } from "../sharedRoutes_new_ui";
import SeekerRegistrationStepOne from "../../components/seeker/details/New_UI/registration/SeekerRegistrationStepOne";
import SeekerRegistrationStepTwo from "../../components/seeker/details/New_UI/registration/SeekerRegistrationStepTwo";



export const primarySeekerRouteChildren: customDataRouteObject[] = [
  ...sharedChildren,
  ...primaryPublicChildren,
  {
    id: "Companies",
    name: "Companies",
    path: "/companies",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <SeekerCompaniesPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "CompanyDetails",
    name: "Company Details",
    path: "/companies/:companyId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <SeekerViewCompanyDetails />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Jobs",
    name: "Jobs",
    path: "/jobs",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <SeekerJobsPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "JobDetails",
    name: "Job Details",
    path: "/jobs/:listingId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <SeekerListingDetails />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Applications",
    name: "Applications",
    path: "/applications",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto  overflow-y-contain">
          <SeekerApplicationsPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Application Details",
    name: "Application Details",
    path: "/application/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <ApplicationProvider>
            <SeekerApplicationsDetails />
          </ApplicationProvider>
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Offers",
    name: "Offers",
    path: "/offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto  overflow-y-contain">
          <SeekerOffersPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Academy",
    name: "Academy",
    path: "/academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className="w-3/4 mx-auto ">
          <SeekerAcademyPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "OtherPositions",
    name: "Other Positions",
    path: "/other-positions/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <OtherPositions />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "PreScreenQuestions",
    name: "Pre Screen Questions",
    path: "/pre-screen-questions/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <SeekerPreScreenPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "MissionComplete",
    name: "Mission Complete",
    path: "/mission-complete/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <MissionComplete />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "SeekerEmailVerification",
    name: "Email Verification",
    path: "/email-verification",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <EmailVerificationPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "Seeker MFA Enroll",
    name: "Multifactor Enrollment",
    path: "/registration/multifactor-enrollment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerMultifactorEnrollmentPage />
      </ErrorBoundary>
    ),
  },
  {
    id: "SeekerOnboarding",
    name: "Career Seeker Onboarding",
    path: "/onboarding",
    element: (
      // @ts-ignorepsha
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerOnboardingPage />
      </ErrorBoundary>
    ),
  },
];
export const seekerRoute: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: primarySeekerRouteChildren,
  },
];


//////////////

export const primarySeekerRouteChildren_newUi: customDataRouteObject[] = [
  ...sharedChildren_newUi,
  ...primaryPublicChildren_newui,
  {
    id: "Companies",
    name: "Companies",
    path: "/companies",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <Companies_newUI />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "CompanyDetails",
    name: "Company Details",
    path: "/companies/:companyId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <SeekerViewCompanyDetails />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Jobs",
    name: "Jobs",
    path: "/jobs",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <Jobs />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "JobsByComapny",
    name: "JobsByComapny",
    path: "/jobs/:comapanyId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto ">
          <Jobs />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Applications",
    name: "Applications",
    path: "/applications",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto  overflow-y-contain">
          <SeekerApplicationsPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Application Details",
    name: "Application Details",
    path: "/application/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <ApplicationProvider>
            <SeekerApplicationsDetails />
          </ApplicationProvider>
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Offers",
    name: "Offers",
    path: "/offers",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto  overflow-y-contain">
          <SeekerOffersPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "Academy",
    name: "Academy",
    path: "/academy",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className="w-3/4 mx-auto ">
          <SeekerAcademyPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "OtherPositions",
    name: "Other Positions",
    path: "/other-positions/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <OtherPositions />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "PreScreenQuestions",
    name: "Pre Screen Questions",
    path: "/pre-screen-questions/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <SeekerPreScreenPage />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "MissionComplete",
    name: "Mission Complete",
    path: "/mission-complete/:applicationId",
    element: (
      // @ts-ignore
      <ErrorBoundary FalbackComponent={Fallback}>
        <div className=" mx-auto overflow-y-contain ">
          <MissionComplete />
        </div>
      </ErrorBoundary>
    ),
  },
  {
    id: "SeekerEmailVerification",
    name: "Email Verification",
    path: "/email-verification",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerRegistrationIndexNew />
      </ErrorBoundary>
    ),
  },
  {
    id: "Seeker MFA Enroll",
    name: "Multifactor Enrollment",
    path: "/registration/multifactor-enrollment",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <SeekerRegistrationIndexNew />
      </ErrorBoundary>
    ),
  },
  {
    id: "SeekerOnboarding",
    name: "Career Seeker Onboarding",
    path: "/onboarding",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
       <Jobs />
      </ErrorBoundary>
    ),
  },


];
export const seekerRoute_newUi: customRoute[] = [
  {
    id: "Index",
    name: "Index",
    path: "/",
    element: (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Fallback}>
        <IndexPage />
      </ErrorBoundary>
    ),
    errorElement: <NotFoundPage />,
    children: primarySeekerRouteChildren_newUi,
  },
];

export const CreateSeekerRoutes = () => {
  const [newConfig, setNewConfig] = useState(true);
  const [routes, setRoutes] = useState(createBrowserRouter(seekerRoute_newUi));
  const fetchSkills = async () => {
    const data = await manageConfiguration();
    if (data[0]?.manage_new_ui) {
      setNewConfig(data[0]?.manage_new_ui);
    } else {
    }
  };
  useEffect(() => {

    fetchSkills();

  }, [])

  useEffect(() => {
    setRoutes(createBrowserRouter(newConfig ? seekerRoute_newUi : seekerRoute))
  }, [newConfig])
  return <RouterProvider router={routes} />;

}
