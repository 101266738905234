import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useLookup } from "../../../data/lookup";
import {
  getIsSubmittingJobFirstTime,
  updateEntryForJobDetailsNewCollection,
  useJobDetailsNewCollection,
} from "../../../data/jobDetailsNewCollection";

import GreenUnderlineHeading from "../../custom-ui/headings/heading";
import { isLockedJobNew } from "../../../util/jobs/jobUtils";

import Input from "../../custom-ui/old-ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { getJobsNewSchema } from "../../../schemas/JobsNewSchema";
import Wysiwyg from "../../custom-ui/old-ui/Wysiwyg";
import SelectGeneric from "../../custom-ui/old-ui/SelectGeneric";
import DateGeneric from "../../custom-ui/old-ui/DateGeneric";
import { JobDetailsNewCollection } from "../../../dataTypes/JobDetailsNewCollection";
import RadioGroup from "../../custom-ui/old-ui/RadioGroup";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/custom-ui/toast-alert/ToastAlert";
import Checkbox from "../../custom-ui/old-ui/Checkbox";
import InputCurrency from "../../custom-ui/old-ui/InputCurrency";
import TextArea from "../../custom-ui/old-ui/TextArea";
import { useMemo } from "react";
import Tooltip from "../../custom-ui/tooltip/tooltip_NewUI";
import SliderWithCheckbox from "../../custom-ui/old-ui/SliderWithCheckbox";
import Select from "../../custom-ui/new-ui/Select";
import { useAuth } from "../../../providers/auth";
import RadioGroup_Emp from "../../employer/details/New_Ui/Component-UI/RadioGroup_Emp";

function JobNewInformation() {
  const params = useParams();
  const { user } = useAuth();
  const targetRef = useRef(null);
  const jobId = params.id ?? "";
  const [error, setError] = useState()
  const [isInitialized, setIsInitialized] = useState(false);
  const [formSchema, setFormSchema] = useState(getJobsNewSchema(false));
  const { data: job } = useJobDetailsNewCollection(jobId);
  const { data: workSetting = [] } = useLookup("workSetting");
  const { data: workType = [] } = useLookup("workType");
  const { data: workTerm = [] } = useLookup("workTerm");
  const { data: payRateTypes = [] } = useLookup("payRateType");
  const { data: solutionTypes = [] } = useLookup("solutionTypeNew");
  const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
  const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
  const { data: mustHaves = [] } = useLookup("mustHaves");

  const aboutThisJobOptions = useMemo(() => {
    return aboutThisJob.map((obj: any) => {
      const labelSplitArray = obj.label.split("<--->");

      const labelLeft = labelSplitArray[0];
      const labelRight = labelSplitArray[1];

      return {
        ...obj,
        labelLeft,
        labelRight,
      };
    });
  }, [aboutThisJob]);

  const isFieldLocked = isLockedJobNew(job?.jobState);
  const [includeDate, setIncludeDate] = useState(isFieldLocked ? job?.startDateInclude : false);

  const lockedJobStatusToolTipText = useMemo(() => {
    let jobState = job?.jobState;

    if (["closed_filled", "closed_cancelled"].includes(jobState)) {
      jobState = "closed";
    } else if (jobState === "on_hold") {
      jobState = "on hold";
    } else {
      jobState = "live";
    }
    return jobState;
  }, [job]);

  const SECTION_LOCKED_MESSAGE = `This section cannot be changed while the job status is ${lockedJobStatusToolTipText}.`;


  type FormValues = z.infer<ReturnType<typeof getJobsNewSchema>>;

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      jobName: "",
      solutionType: "",
      startDate: "",
      openings: "",
      applicationFlow: "",
      workSettings: "",
      workType: "",
      workTerm: "",
      location: job?.hybridJobLocation || "",
      hoursPerWeekMin: "",
      hoursPerWeekMax: "",
      payRateType: "",
      salaryMin: "",
      salaryMax: "",
      hourlyMin: "",
      hourlyMax: "",
      isCommission: false,
      commission: "",
      unpaidInternship: "",
      additionalBenifits:"",
      jobSummary: "",
      whatsInItForYou: "",
      dayInLife: "",
      mustHaves: "",
      aspectsOfJob: [],
      isAspectsOfJobOther: false,
      aspectsOfJobOther: "",
      aboutThisJob: [],
      mustHavesCheckboxes: [],
      noATS: "noATS_true",
      noATSContactEmail: "",
    },
    reValidateMode: "onBlur",
  });

  const isSubmittingJobFirstTime = useMemo(
    () => getIsSubmittingJobFirstTime(job),
    [job],
  );

  const {
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = form;


  const payRateType = watch("payRateType");
  // Watch for the value of isCommission
  const isCommission = watch("isCommission");
  // Watch for the value of aspectsOfJob
  const aspectsOfJobWatch = watch("aspectsOfJob");
  // Watch for the value of isAspectsOfJobOther
  const isAspectsOfJobOther = watch("isAspectsOfJobOther");
  // Watch for the value of mustHavesCheckboxes
  const mustHavesCheckboxesWatch = watch("mustHavesCheckboxes");
  // Watch for the value of mustHavesCheckboxes
  const aboutThisJobWatch = watch("aboutThisJob");
  // Watch for the value of jobSummary
  const jobSummaryWatch = watch("jobSummary");
  // Watch for the value of aspectsOfJobOther
  const aspectsOfJobOtherWatch = watch("aspectsOfJobOther");
  const additionalBenifits_watch = watch("additionalBenifits");
  const noATS_watch = watch("noATS");



  useEffect(() => {
    if (additionalBenifits_watch) {
      setValue("commission", "");
    }

  }, [additionalBenifits_watch]);


  const onSubmit = async ({ ...values }: FormValues) => {

    if (includeDate) {
      if (!values?.startDate) {
        targetRef.current?.scrollIntoView({ behavior: 'smooth' });
        setError("Start Date is Required")
        return;
      }
    }
    const {
      jobSummary,
      whatsInItForYou,
      dayInLife,
      mustHaves,
      startDate,
      aspectsOfJob = [],
      aspectsOfJobOther = "",
      isAspectsOfJobOther,

      aboutThisJob = [],

      mustHavesCheckboxes = [],

      hoursPerWeekMin,
      hoursPerWeekMax,
      payRateType,
      salaryMin,
      salaryMax,
      hourlyMin,
      hourlyMax,
      commission,
      unpaidInternship,
      additionalBenifits,
      location,
      noATS,
      noATSContactEmail,
      ...restValues
    } = values;

    const jobDetailsNewCollectionData: any = {
      ...restValues,
      // jobName: title,
      companyId: job.companyId,
      creationDate: job.creationDate,
      jobState: job.jobState,
      startDateInclude: includeDate,
      // giving null placeholder values to the fields which have not been filled yet
      // applicationFlow: null,
      applyNowCounter: job?.applyNowCounter || 0,
      clicksCounter: job?.clicksCounter || 0,
      startDate: startDate || "",
      closedFilledBy: "",
      closedReason: "",
      hybridJobLocation: location || job?.hybridJobLocation || "",
      companyFullName: job?.companyFullName,
      companyLogo: job?.companyLogo,
      companyHQ: job?.companyHQ,
      hoursPerWeek: {
          min: Number(hoursPerWeekMin),
          max: Number(hoursPerWeekMax),
      },
      noATS: noATS === "noATS_true" ? false : true,
      noATSContactEmail: noATS === "noATS_false" ? noATSContactEmail : "",
      instantTeamJobs: null,
      jobDescription: {
        whatsInItForYou:
          isSubmittingJobFirstTime ||
            job?.jobDescription?.whatsInItForYou === null
            ? null
            : whatsInItForYou || "",
        dayInLife: dayInLife || jobSummary || "",
        mustHaves:
          isSubmittingJobFirstTime ||
            mustHavesCheckboxes.length ||
            job?.jobDescription?.mustHaves === null
            ? null
            : mustHaves || "",

        aspectsOfJob,
        aspectsOfJobOther: isAspectsOfJobOther ? aspectsOfJobOther : "",
        aboutThisJob,
        mustHavesCheckboxes,
      },
      makeLiveDate: job.makeLiveDate,
      openings: Number(values.openings),
      postedBy: {
        userId: user?.uid || "",
        userName: "TAC Admin"
      },
      payRate: {
          type: payRateType,
          ...(["salary", "hourly", "commission"].includes(payRateType) &&
              commission !== "" &&
              commission !== undefined
              ? {
                  additionalBenifits: additionalBenifits || "",
                  commission: commission,
              }
              : {
                  commission: null,
              }),
          ...(payRateType === "unpaid_internship" &&
              unpaidInternship !== "" &&
              unpaidInternship !== undefined
              ? {
                  unpaidInternship: unpaidInternship,
              }
              : {
                  unpaidInternship: null,
              }),
          ...(payRateType === "salary" &&
              salaryMin !== "" &&
              salaryMax !== "" &&
              salaryMin !== undefined &&
              salaryMax !== undefined
              ? {
                  salary: {
                      min: Number(salaryMin),
                      max: Number(salaryMax),
                  },
              }
              : {
                  salary: null,
              }),
          ...(payRateType === "hourly" &&
              hourlyMin !== "" &&
              hourlyMax !== "" &&
              hourlyMin !== undefined &&
              hourlyMax !== undefined
              ? {
                  hourly: {
                      min: Number(hourlyMin),
                      max: Number(hourlyMax),
                  },
              }
              : {
                  hourly: null,
              }),
      },
  };

  await updateEntryForJobDetailsNewCollection(
      jobId,
      jobDetailsNewCollectionData,
  )
      .then((data) => {
          toast.success("Job (New) updated successfully");
      })
      .catch(() => {
          toast.error("There was an error updating Job (New)");
      });
};


  const setIntialValuesToForm = (jobData: any) => {


    if (!jobData || (jobData && !Object.keys(jobData).length)) return;
    setValue("payRateType", jobData?.payRate?.type ?? "");
    setValue("jobName", jobData?.jobName ?? "");
    setValue("solutionType", job?.solutionType ?? "");
    // setValue("location", jobData?.hybridJobLocation ?? "");
    setValue("startDate", isFieldLocked && job?.startDate ? job?.startDate?.toDate() : "");
    setValue("openings", jobData?.openings ?? "");
    setValue("applicationFlow", jobData?.applicationFlow ?? "");
    setValue("workSettings", jobData?.workSettings ?? "");
    setValue("workTerm", jobData?.workTerm ?? "");
    setValue("workType", jobData?.workType ?? "");
    setValue("hoursPerWeekMin", jobData?.hoursPerWeek?.min ?? "");
    setValue("hoursPerWeekMax", jobData?.hoursPerWeek?.max ?? "");
    setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);
    setValue("jobSummary", jobData?.jobDescription?.jobSummary ?? "");
    setValue("whatsInItForYou", jobData?.jobDescription?.whatsInItForYou ?? "");
    setValue("dayInLife", jobData?.jobDescription?.dayInLife ?? "");
    setValue("additionalBenifits", jobData?.payRate?.additionalBenifits ?? null);

    // location: job?.hybridJobLocation || "",
    // additionalBenifits: job?.payRate?.additionalBenifits || "",
    // noATS: job?.noATS ? "noATS_false" : "noATS_true",
    // noATSContactEmail: job?.noATSContactEmail || ""

    if (
      jobData?.jobDescription?.dayInLife &&
      jobData?.jobDescription?.dayInLife.length
    ) {
      setValue("jobSummary", jobData?.jobDescription?.dayInLife ?? "");
    }

    setValue("mustHaves", jobData?.jobDescription?.mustHaves ?? "");

    setTimeout(() => {
      if (jobData?.payRate?.type === "salary") {
        setValue("salaryMin", String(jobData?.payRate?.salary?.min) ?? "");
        setValue("salaryMax", String(jobData?.payRate?.salary?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
        setValue("additionalBenifits", jobData?.payRate?.additionalBenifits ?? null);

      } else if (jobData?.payRate?.type === "hourly") {
        setValue("hourlyMin", String(jobData?.payRate?.hourly?.min) ?? "");
        setValue("hourlyMax", String(jobData?.payRate?.hourly?.max) ?? "");
        setValue("commission", jobData?.payRate?.commission ?? null);
        setValue("additionalBenifits", jobData?.payRate?.additionalBenifits ?? null);
      } else if (jobData?.payRate?.type === "commission") {
        setValue("commission", jobData?.payRate?.commission ?? "");
      } else if (jobData?.payRate?.type === "unpaid_internship") {
        setValue("unpaidInternship", jobData?.payRate?.unpaidInternship ?? "");
      }

      setTimeout(() => {
        setValue(
          "isCommission",
          jobData?.payRate?.commission?.length ? true : false,
        );

        setTimeout(() => {
          if (jobData?.payRate?.type === "salary") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          } else if (jobData?.payRate?.type === "hourly") {
            setValue("commission", jobData?.payRate?.commission ?? null);
          }

          setValue("aspectsOfJob", jobData?.jobDescription?.aspectsOfJob ?? []);

          setValue("aboutThisJob", jobData?.jobDescription?.aboutThisJob ?? []);

          setValue(
            "mustHavesCheckboxes",
            jobData?.jobDescription?.mustHavesCheckboxes ?? [],
          );

          if (jobData?.jobDescription?.aspectsOfJobOther?.length) {
            setValue("isAspectsOfJobOther", true);

            setTimeout(() => {
              setValue(
                "aspectsOfJobOther",
                jobData?.jobDescription?.aspectsOfJobOther ?? "",
              );
            }, 100);
          } else {
            setValue("isAspectsOfJobOther", false);
          }
        }, 100);
      }, 500);
    }, 1000);
  };

  const handleAddAboutThisJob = (
    code: string,
    labelLeft: string,
    labelRight: string,
    sliderValue: string,
    remove: boolean,
  ) => {
    let newAboutThisJob = [...(aboutThisJobWatch || [])];

    if (remove) {
      newAboutThisJob = newAboutThisJob.filter((obj: any) => obj.code !== code);

      setValue("aboutThisJob", newAboutThisJob);
      return;
    }

    const newData = {
      code,
      labelLeft,
      labelRight,
      value: Number(sliderValue),
    };

    if (newAboutThisJob.filter((obj: any) => obj.code === code).length) {
      newAboutThisJob = newAboutThisJob?.map((aboutThisJobObj: any) => {
        if (aboutThisJobObj.code !== code) {
          return aboutThisJobObj;
        }

        return newData;
      });
    } else {
      newAboutThisJob.push(newData);
    }

    const newAboutThisJobSorted: any = [];

    aboutThisJobOptions.forEach((aboutThisJobOptionsObj: any) => {
      const isFound = newAboutThisJob.find(
        (obj: any) => obj.code === aboutThisJobOptionsObj.code,
      );
      if (isFound) {
        newAboutThisJobSorted.push(isFound);
      }
    });

    setValue("aboutThisJob", newAboutThisJobSorted);
  };

  useEffect(() => {
    if (job && !isInitialized) {
      setIntialValuesToForm(job);
      reset({
        ...getValues(),
        location: job?.hybridJobLocation || "",
        additionalBenifits: job?.payRate?.additionalBenifits || "",
        noATS: job?.noATS ? "noATS_false" : "noATS_true",
        noATSContactEmail: job?.noATSContactEmail || ""
      });
      setIsInitialized(true);
    }
  }, [job, isInitialized]);

  // Handle the statusId change and dynamically update the schema
  useEffect(() => {
    if (isSubmittingJobFirstTime) {
      const newSchema = getJobsNewSchema(isSubmittingJobFirstTime);
      setFormSchema(newSchema);
      setIntialValuesToForm(job);
    } else {
      const newSchema = getJobsNewSchema(false);
      setFormSchema(newSchema);
    }
  }, [isSubmittingJobFirstTime, reset]);



  if (!job) return <></>;


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row justify-center">
          <div className="w-auto min-h-screen max-w-[850px]">
            <div ref={targetRef} className="grid gap-1 px-10 grid-cols-1 md:grid-cols-1 mb-4 mt-4">
              <GreenUnderlineHeading
                title="Job Information"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />

              <Controller
                control={control}
                name="jobName"
                render={(field) => (
                  <Input
                    {...field}
                    display="Job Title"
                    placeholder="Enter Job Title"
                    readOnly={isFieldLocked}
                    error={errors?.jobName?.message}
                    required
                  />
                )}
              />

              {isFieldLocked ? (
                <Controller
                  control={control}
                  name="solutionType"
                  render={(field) => {
                    const { value, ...restField } = field;
                    const filteredValue = solutionTypes.find(
                      (st: any) => st.code === value,
                    );

                    const solutionTypeText = filteredValue?.label ?? "";

                    return (
                      <Input
                        {...restField}
                        value={solutionTypeText}
                        display="Solution Type"
                        placeholder="Solution Type"
                        readOnly={isFieldLocked}
                        error={errors?.solutionType?.message}
                        required
                      />
                    );
                  }}
                />
              ) : (
                <Controller
                  control={control}
                  name="solutionType"
                  render={(field) => (
                    <SelectGeneric
                      {...field}
                      options={solutionTypes}
                      display="Solution Type"
                      placeholder="Select a Solution Type"
                      disabled={isFieldLocked}
                      error={errors?.solutionType?.message}
                      required
                    />
                  )}
                />
              )}

              <div className="mt-2 my-3 flex ">
                <div className="flex items-center mb-2">
                  <input
                    type="radio"
                    id="includeDate"
                    name="dateOption"
                    value="includeDate"
                    disabled={isFieldLocked}
                    checked={includeDate}
                    onChange={() => setIncludeDate(true)}
                    className="mr-2 "
                  />
                  <label className="mr-5" htmlFor="includeDate">Include Start Date</label>
                </div>
                <div className="flex items-center mb-2">
                  <input
                    type="radio"
                    id="notIncludeDate"
                    name="dateOption"
                    value="notIncludeDate"
                    disabled={isFieldLocked}

                    checked={!includeDate}
                    onChange={() => setIncludeDate(false)}
                    className="mr-2"
                  />
                  <label htmlFor="notIncludeDate">Do Not Include Start Date</label>
                </div>
              </div>
              {includeDate && (
                <Controller
                  control={control}
                  name="startDate"
                  render={(field) => (
                    <DateGeneric
                      {...field}
                      display="Start Date"
                      timestamp={true}
                      readOnly={isFieldLocked}
                      error={errors?.startDate?.message}
                      required
                    />
                  )}
                />
              )}
              {error && includeDate && <p className="text-red-500 mt-[-10px]  text-base" >{error}</p>}

              <Controller
                control={control}
                name="openings"
                render={(field) => {
                  const { value, onChange, ...restField } = field;

                  return (
                    <Input
                      {...restField}
                      value={value === "" ? "" : Number(value)}
                      onChange={(e: any) => {
                        if (isNaN(Number(e.target.value))) {
                          return;
                        }

                        const valueAsNumber =
                          e.target.value === "" ? "" : Number(e.target.value);

                        onChange(valueAsNumber);
                      }}
                      display="Openings"
                      placeholder="Enter Openings"
                      readOnly={isFieldLocked}
                      error={errors?.openings?.message}
                      required
                    />
                  );
                }}
              />

              <div className="flex items-center ">
                <h1 className="text-instant-teams-blue-Main text-lg flex mb-2">
                  Do you have an ATS System (Application Tracking System) <span className="text-red-500 text-base ml-1">*</span>
                </h1>
              </div>
              <Controller
                control={control}
                name="noATS"
                render={(field) => (
                  <RadioGroup_Emp
                    {...field}
                    options={[
                      {
                        "id": "noATS_true",
                        "icon": null,
                        "isActive": true,
                        "label": "Yes",
                        "code": "noATS_true",
                      },
                      {
                        "id": "noATS_false",
                        "isActive": true,
                        "label": "No",
                        "icon": null,
                        "code": "noATS_false"
                      }
                    ]}
                    disabled={isFieldLocked}
                    error={errors?.noATS?.message}
                    required
                  />
                )}
              />
              {noATS_watch === "noATS_false" && <div className="flex mt-2 justify-center">
                <Controller
                  control={control}
                  name="noATSContactEmail"
                  render={(field) => (
                    <Input
                      {...field}
                      display="Please Enter Your Email Address for Receiving Application for This Job"
                      placeholder="Enter email id"
                      readOnly={isFieldLocked}
                      error={errors?.noATSContactEmail?.message}
                      required
                    />
                  )}
                />
              </div>}
              {noATS_watch === "noATS_true" && <Controller
                control={control}
                name="applicationFlow"
                render={(field) => (
                  <Input
                    {...field}
                    display="Application Flow"
                    placeholder="Enter Application Flow"
                    readOnly={isFieldLocked}
                    error={errors?.applicationFlow?.message}
                    required
                  />
                )}
              />}

              <GreenUnderlineHeading
                title="Work Setting"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workSettings"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workSetting}
                    disabled={isFieldLocked}
                    error={errors?.workSettings?.message}
                    required
                  />
                )}
              />

              {form.watch("workSettings") === "hybrid" && (
                <div className="w-full  justify-start">
                  <h1 className="text-instant-teams-blue-Main text-[18px] py-3 pb-4 flex font-[400] mt-2">
                    Please enter your hybrid location<span className="text-red-500 text-base ml-1">*</span>
                  </h1>

                  <Controller
                    control={control}
                    name="location"
                    render={(field) => (
                      <Controller
                        control={control}
                        name="location"
                        render={(field) => (
                          <Input
                            {...field}
                            display="Please enter your hybrid location"
                            placeholder="Enter location"

                            readOnly={isFieldLocked}
                            error={errors?.location?.message}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </div>
              )}

              <GreenUnderlineHeading
                title="Work Type"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workType"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workType}
                    disabled={isFieldLocked}
                    error={errors?.workType?.message}
                    required
                  />
                )}
              />

              <GreenUnderlineHeading
                title="Work Per Week (Hrs) (optional for full-time)"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <div className="flex flex-row gap-[20px]">
                <Controller
                  control={control}
                  name="hoursPerWeekMin"
                  render={(field) => {
                    const { value, onChange, ...restField } = field;

                    return (
                      <Input
                        {...restField}
                        value={value === "" ? "" : value}
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          const isValidInput = /^\d*\.?\d{0,1}$/.test(
                            inputValue,
                          );
                          if (!isValidInput) return;
                          onChange(inputValue);
                        }}
                        display="Min (Hrs)"
                        placeholder="Min (Hrs)"
                        readOnly={isFieldLocked}
                        error={errors?.hoursPerWeekMin?.message}
                        required
                      />
                    );
                  }}
                />
                <Controller
                  control={control}
                  name="hoursPerWeekMax"
                  render={(field) => {
                    const { value, onChange, ...restField } = field;

                    return (
                      <Input
                        {...restField}
                        value={value === "" ? "" : value}
                        onChange={(e: any) => {
                          const inputValue = e.target.value;
                          const isValidInput = /^\d*\.?\d{0,1}$/.test(
                            inputValue,
                          );
                          if (!isValidInput) return;
                          onChange(inputValue);
                        }}
                        display="Max (Hrs)"
                        placeholder="Max (Hrs)"
                        readOnly={isFieldLocked}
                        error={errors?.hoursPerWeekMax?.message}
                        required
                      />
                    );
                  }}
                />
              </div>

              <GreenUnderlineHeading
                title="Work Term"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="workTerm"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={workTerm}
                    disabled={isFieldLocked}
                    error={errors?.workTerm?.message}
                    required
                  />
                )}
              />



              <GreenUnderlineHeading
                title="Pay Rate"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />
              <Controller
                control={control}
                name="payRateType"
                render={(field) => (
                  <RadioGroup
                    {...field}
                    options={payRateTypes}
                    disabled={isFieldLocked}
                    error={errors?.payRateType?.message}
                    required
                  />
                )}
              />

              {payRateType === "salary" && (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="salaryMin"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMin?.message}
                            required
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="salaryMax"
                      render={(field) => {
                        const { value, onChange, ...restField } = field;

                        return (
                          <InputCurrency
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.salaryMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForSalary"
                          label="Additional Comp Incentives"
                          disabled={isFieldLocked}
                          error={errors?.workSettings?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2">
                        <div className="my-5">
                          <Controller
                            control={control}
                            name="additionalBenifits"
                            render={(field) => (
                              <Select
                                {...field}
                                options={[{
                                  "code": "empComm",
                                  "description": "PostJob",
                                  "group": "emp_postjob",
                                  "icon": null,
                                  "isActive": true,
                                  "label": "Commission",
                                  "sortOrder": 1
                                }, {
                                  "code": "empBO",
                                  "description": "PostJob",
                                  "group": "emp_PostJob",
                                  "icon": null,
                                  "isActive": true,
                                  "label": "Bonus",
                                  "sortOrder": 2
                                }]}
                                display="Incentive Type"
                                placeholder="Select Incentive Type"
                                value={job?.payRate?.additionalBenifits || ""}
                                disabled={isFieldLocked}
                                error={errors?.additionalBenifits?.message}
                              />
                            )}
                          />
                        </div>

                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <Wysiwyg
                              {...field}
                              rows={10}
                              display={additionalBenifits_watch === "empBO" ? "Bonus" : "Commission"}
                              readOnly={isFieldLocked}
                              placeholder={additionalBenifits_watch === "empBO" ? " Enter Bonus" : " Enter Commission"}
                              directions=""
                              editorStyle={{ height: "240px" }}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "hourly" && (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="hourlyMin"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Min"
                            placeholder="Min"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMin?.message}
                            required
                          />
                        );
                      }}
                    />
                    <Controller
                      control={control}
                      name="hourlyMax"
                      render={(field) => {
                        return (
                          <InputCurrency
                            {...field}
                            display="Max"
                            placeholder="Max"
                            readOnly={isFieldLocked}
                            error={errors?.hourlyMax?.message}
                            required
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col gap-[20px] mt-2">
                    <Controller
                      control={control}
                      name="isCommission"
                      render={(field) => (
                        <Checkbox
                          {...field}
                          id="isCommissionForHourly"
                          label="Additional Comp Incentives"
                          disabled={isFieldLocked}
                          error={errors?.workSettings?.message}
                          required
                        />
                      )}
                    />
                    {isCommission && (
                      <div className="mb-2">
                        <div className="my-5">
                          <Controller
                            control={control}
                            name="additionalBenifits"
                            render={(field) => (
                              <Select
                                {...field}
                                options={[{
                                  "code": "empComm",
                                  "description": "PostJob",
                                  "group": "emp_postjob",
                                  "icon": null,
                                  "isActive": true,
                                  "label": "Commission",
                                  "sortOrder": 1
                                }, {
                                  "code": "empBO",
                                  "description": "PostJob",
                                  "group": "emp_PostJob",
                                  "icon": null,
                                  "isActive": true,
                                  "label": "Bonus",
                                  "sortOrder": 2
                                }]}
                                display="Incentive Type"
                                value={job?.payRate?.additionalBenifits || ""}
                                placeholder="Select Additional Benifits"
                                disabled={isFieldLocked}
                                error={errors?.additionalBenifits?.message}
                              />
                            )}
                          />
                        </div>

                        <Controller
                          control={control}
                          name="commission"
                          render={(field) => (
                            <Wysiwyg
                              {...field}
                              rows={10}
                              display={additionalBenifits_watch === "empBO" ? "Bonus" : "Commission"}
                              readOnly={isFieldLocked}
                              placeholder={"Enter Commission"}
                              directions=""
                              editorStyle={{ height: "240px" }}
                              error={errors?.commission?.message}
                              required
                            />
                          )}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {payRateType === "commission" && (
                <div className="mt-2">
                  {/* <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Commission"
                        placeholder="Enter Commission"
                        readOnly={isFieldLocked}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  /> */}

                  <Controller
                    control={control}
                    name="commission"
                    render={(field) => (
                      <Wysiwyg
                        {...field}
                        rows={10}
                        display="Commission"
                        readOnly={isFieldLocked}
                        placeholder={"Enter Commission"}
                        directions=""
                        editorStyle={{ height: "240px" }}
                        error={errors?.commission?.message}
                        required
                      />
                    )}
                  />
                </div>
              )}

              {payRateType === "unpaid_internship" && (
                <div className="mt-2">
                  {/* <Controller
                    control={control}
                    name="unpaidInternship"
                    render={(field) => (
                      <TextArea
                        {...field}
                        display="Free Internship"
                        placeholder="Enter Free Internship"
                        readOnly={isFieldLocked}
                        error={errors?.unpaidInternship?.message}
                        required
                      />
                    )}
                  /> */}
                </div>
              )}

              <GreenUnderlineHeading
                title="Job Description"
                showLockIcon={true}
                isLocked={isFieldLocked}
                text={SECTION_LOCKED_MESSAGE}
              />

              <div className="mb-2">
                {isSubmittingJobFirstTime ||
                  (job?.jobDescription?.whatsInItForYou === null &&
                    job?.jobDescription?.dayInLife) ? (
                  <>
                    <div className="relative text-instant-teams-teal-Main font-bold pl-1 pr-[20px] mb-[20px]">
                      Please provide a brief summary of the Job Description in
                      2-3 sentences *{isFieldLocked ? " - LOCKED" : null}
                      <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">
                        <Tooltip
                          text={
                            <div className="font-[400]">
                              This will be displayed as a short summary of the
                              job on the Instant teams website
                            </div>
                          }
                        >
                          <img
                            src="/images/info.png"
                            alt="Info Icon"
                            className="w-4"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <Controller
                        control={control}
                        name="jobSummary"
                        render={(field) => (
                          <>
                            <TextArea
                              {...field}
                              display=""
                              placeholder="Enter Job Summary"
                              readOnly={isFieldLocked}
                              error={errors?.jobSummary?.message}
                              maxLength={250}
                            // required
                            />
                            <p className="w-full flex justify-end text-[12px] text-[#afafaf] -mt-3">
                              {(jobSummaryWatch?.length || 0) > 0 ? (
                                <>{jobSummaryWatch?.length} / 250 Characters</>
                              ) : (
                                <>Max 250 Characters</>
                              )}
                            </p>
                          </>
                        )}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>

              <div className="mb-2">
                {isSubmittingJobFirstTime ||
                  job?.jobDescription?.whatsInItForYou === null ? (
                  <>
                    <div className="relative text-instant-teams-teal-Main font-bold pl-1 pr-[20px] mb-[20px]">
                      What aspects of this job might surprise or excite
                      potential candidates? *
                      {isFieldLocked ? " - LOCKED" : null}
                      <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">
                        <Tooltip
                          text={
                            <div className="font-[400]">
                              Here you can describe what your company offers
                              employees that makes this job different from
                              others. This is a great space to highlight the
                              military-friendly aspects of this role! This will
                              become the <b>"Why Work Here"</b> section of your
                              job ad.
                            </div>
                          }
                        >
                          <img
                            src="/images/info.png"
                            alt="Info Icon"
                            className="w-4"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                      {aspectsOfJob.map((aspectsOfJobObj: any) => {
                        return (
                          <div key={aspectsOfJobObj.id}>
                            <Controller
                              control={control}
                              name="aspectsOfJob"
                              render={(field) => {
                                const { value, onChange, ...restField } = field;

                                const fieldValue = aspectsOfJobWatch || [];

                                return (
                                  <Checkbox
                                    {...restField}
                                    id={aspectsOfJobObj.id}
                                    label={aspectsOfJobObj.label}
                                    disabled={isFieldLocked}
                                    value={fieldValue.includes(
                                      aspectsOfJobObj.code,
                                    )}
                                    onChange={(isChecked: boolean) => {
                                      let newValue = [...fieldValue];

                                      if (isChecked) {
                                        newValue.push(aspectsOfJobObj.code);
                                      } else {
                                        newValue = newValue.filter(
                                          (v) => v !== aspectsOfJobObj.code,
                                        );
                                      }

                                      const newValueSorted: any = [];
                                      aspectsOfJob.forEach(
                                        (aspectsOfJobObj: any) => {
                                          if (
                                            newValue.includes(
                                              aspectsOfJobObj.code,
                                            )
                                          ) {
                                            newValueSorted.push(
                                              aspectsOfJobObj.code,
                                            );
                                          }
                                        },
                                      );

                                      onChange(newValueSorted);
                                    }}
                                    containerClassName="grid-cols-1"
                                    required
                                  />
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                      <div className="flex flex-col gap-[20px]">
                        <Controller
                          control={control}
                          name="isAspectsOfJobOther"
                          render={(field) => (
                            <Checkbox
                              {...field}
                              id="isAspectsOfJobOtherForAspectsOfJob"
                              label="Other"
                              disabled={isFieldLocked}
                              error={false}
                              required
                            />
                          )}
                        />
                        {isAspectsOfJobOther && (
                          <div className="mb-2 mt-[-10px]">
                            <Controller
                              control={control}
                              name="aspectsOfJobOther"
                              render={(field) => (
                                <>
                                  <TextArea
                                    {...field}
                                    display=""
                                    placeholder="Please write here"
                                    readOnly={isFieldLocked}
                                    error={errors?.aspectsOfJobOther?.message}
                                    required={false}
                                    maxLength={200}
                                  />
                                  <p className="w-full flex justify-end text-[12px] text-[#afafaf] -mt-3">
                                    {(aspectsOfJobOtherWatch?.length || 0) >
                                      0 ? (
                                      <>
                                        {aspectsOfJobOtherWatch?.length} / 200
                                        Characters
                                      </>
                                    ) : (
                                      <>Max 200 Characters</>
                                    )}
                                  </p>
                                </>
                              )}
                            />
                          </div>
                        )}
                        {(errors as any)?.aspectsOfJobRoot && (
                          <p className="text-[0.8rem] font-medium text-[red] mt-1">
                            {(errors as any).aspectsOfJobRoot.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Controller
                    control={control}
                    name="whatsInItForYou"
                    render={(field) => (
                      <Wysiwyg
                        {...field}
                        rows={10}
                        display="What's in it for you?"
                        readOnly={isFieldLocked}
                        placeholder={"What's in it for you?"}
                        directions=""
                        editorStyle={{ height: "240px" }}
                        error={errors?.whatsInItForYou?.message}
                        required
                      />
                    )}
                  />
                )}
              </div>

              <hr />

              <div className="mb-2">
                {isSubmittingJobFirstTime ||
                  job?.jobDescription?.whatsInItForYou === null ? (
                  <>
                    <div className="relative text-instant-teams-teal-Main font-bold pl-1 pr-[20px] mb-[20px]">
                      Tell Us About This Job: Use the sliders to give a quick
                      snapshot of what to expect! *
                      {isFieldLocked ? " - LOCKED" : null}
                      <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">
                        <Tooltip
                          text={
                            <div className="font-[400]">
                              This will become the <b>"Instant Job Insights"</b>{" "}
                              section of your job ad.
                            </div>
                          }
                        >
                          <img
                            src="/images/info.png"
                            alt="Info Icon"
                            className="w-4"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                      <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                        You can choose which “Instant Job Insights” appear on
                        your post by checking the boxes next to each slider
                      </div>
                      <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                        (Please choose a maximum of 5 out of the following).
                      </div>

                      {aboutThisJobOptions.map((option: any) => (
                        <Controller
                          key={option.id}
                          name="aboutThisJob"
                          control={control}
                          render={(field) => {
                            const { value, onChange, ...restField } = field;

                            const fieldValue = aboutThisJobWatch || [];

                            const isDisabled =
                              fieldValue.length === 5 &&
                              !fieldValue.find(
                                (obj: any) => obj.code === option.code,
                              );

                            const valueOfField = fieldValue.find(
                              (obj: any) => obj.code === option.code,
                            );

                            return (
                              <SliderWithCheckbox
                                option={option}
                                value={valueOfField ? valueOfField.value : 50}
                                isChecked={valueOfField ? true : false}
                                onSelectionChange={(
                                  sliderValue: any,
                                  remove: boolean = false,
                                ) =>
                                  handleAddAboutThisJob(
                                    option.code,
                                    option.labelLeft, // Left label
                                    option.labelRight, // Right label
                                    sliderValue, // The selected slider value
                                    remove,
                                  )
                                }
                                readOnly={isFieldLocked}
                                disabled={isDisabled}
                              />
                            );
                          }}
                        />
                      ))}

                      <div className="flex flex-col gap-[20px]">
                        {(errors as any)?.aboutThisJob && (
                          <p className="text-[0.8rem] font-medium text-[red] mt-1">
                            {(errors as any).aboutThisJob.message}
                          </p>
                        )}
                        {(errors as any)?.aboutThisJobRoot && (
                          <p className="text-[0.8rem] font-medium text-[red] mt-1">
                            {(errors as any).aboutThisJobRoot.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Controller
                    control={control}
                    name="dayInLife"
                    render={(field) => (
                      <Wysiwyg
                        {...field}
                        rows={10}
                        display="Day in the Life"
                        readOnly={isFieldLocked}
                        placeholder={"Day in the Life"}
                        directions=""
                        editorStyle={{ height: "240px" }}
                        error={errors?.dayInLife?.message}
                        required
                      />
                    )}
                  />
                )}
              </div>

              <hr />

              <div className="mb-2">
                {isSubmittingJobFirstTime ||
                  job?.jobDescription?.whatsInItForYou === null ? (
                  <>
                    <div className="relative text-instant-teams-teal-Main font-bold pl-1 pr-[20px] mb-[20px]">
                      What are the "must haves" for success in this role? *
                      {isFieldLocked ? " - LOCKED" : null}
                      <div className="absolute inset-y-0 right-0 top-[4px] flex items-start cursor-pointer">
                        <Tooltip
                          text={
                            <div className="font-[400]">
                              What are the most important qualities that you are
                              looking for in this role? This will become the{" "}
                              <b>"Must Haves for Success"</b> section of your
                              job ad.
                            </div>
                          }
                        >
                          <img
                            src="/images/info.png"
                            alt="Info Icon"
                            className="w-4"
                          />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                      <div className="text-[0.8rem] font-medium text-[#616161] -mt-[10px]">
                        choose 5 of the most important must haves
                      </div>

                      {mustHaves.map((mustHavesObj: any) => {
                        return (
                          <div key={mustHavesObj.id}>
                            <Controller
                              control={control}
                              name="mustHavesCheckboxes"
                              render={(field) => {
                                const { value, onChange, ...restField } = field;

                                const fieldValue: string[] =
                                  mustHavesCheckboxesWatch || [];

                                const isFieldValue = fieldValue.includes(
                                  mustHavesObj?.code,
                                );

                                const isDisabled =
                                  fieldValue.length === 5 && !isFieldValue;

                                return (
                                  <Checkbox
                                    {...restField}
                                    id={mustHavesObj.id}
                                    label={mustHavesObj.label}
                                    disabled={isFieldLocked || isDisabled}
                                    value={isFieldValue}
                                    onChange={(isChecked: boolean) => {
                                      let newValue = [...fieldValue];

                                      if (isChecked) {
                                        newValue.push(mustHavesObj.code);
                                      } else {
                                        newValue = newValue.filter(
                                          (v) => v !== mustHavesObj.code,
                                        );
                                      }

                                      const newValueSorted: any = [];
                                      mustHaves.forEach((mustHavesObj: any) => {
                                        if (
                                          newValue.includes(mustHavesObj.code)
                                        ) {
                                          newValueSorted.push(
                                            mustHavesObj.code,
                                          );
                                        }
                                      });

                                      onChange(newValueSorted);
                                    }}
                                    containerClassName="grid-cols-1"
                                    required
                                  />
                                );
                              }}
                            />
                          </div>
                        );
                      })}
                      <div className="flex flex-col gap-[20px]">
                        {(errors as any)?.mustHavesCheckboxes && (
                          <p className="text-[0.8rem] font-medium text-[red] mt-1">
                            {(errors as any).mustHavesCheckboxes.message}
                          </p>
                        )}
                        {(errors as any)?.mustHavesCheckboxesRoot && (
                          <p className="text-[0.8rem] font-medium text-[red] mt-1">
                            {(errors as any).mustHavesCheckboxesRoot.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <Controller
                    control={control}
                    name="mustHaves"
                    render={(field) => (
                      <Wysiwyg
                        {...field}
                        rows={10}
                        display="Must Haves/What You Bring to the Table"
                        readOnly={isFieldLocked}
                        placeholder={"Must Haves/What You Bring to the Table"}
                        directions=""
                        editorStyle={{ height: "240px" }}
                        error={errors?.mustHaves?.message}
                        required
                      />
                    )}
                  />
                )}
              </div>

              {!isFieldLocked && (
                <div className="mt-3 flex flex-row justify-center gap-[36px]">
                  <button
                    type="button"
                    disabled={isSubmitting}
                    className="h-[60px] w-[210px] rounded-[70px] border-[1px] border-[#15415E] py-[20px] px-[70px] hover:border-[#1d5174] text-[#15415E] text-[16px] leading-[19.6px] font-[400]"
                    onClick={() => reset()}
                  >
                    Clear All
                  </button>

                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="h-[60px] w-[210px] rounded-[70px] bg-[#15415E] py-[20px] px-[70px] hover:bg-[#1d5174] text-[#FFFFFF] text-[16px] leading-[19.6px] font-[400]"
                  >
                    {isSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      <ToastAlert />
    </>
  );
}

export default JobNewInformation;



