import { useState } from "react";
import { upload } from "../../../../storage/storage";
import folderNames from "../../../../storage/dictionary/folderNames";
import { friendlyFirebaseError } from "../../../../providers/firebase-errors/friendlyFirebaseError";
import { writeResume } from "../../../../data/seeker";
import DragAndDropVariantB from "../../../custom-ui/drag-and-drop/DragAndDropFileSmall";
import SeekerFiles from "../parts/SeekerFiles";
import { updateAutoSaveForResume } from "../../../../data/utility";
import { collectionNames } from "../../../../data/dictionary/collectionNames";
import toast from "react-hot-toast";
import AutoSavePlainText from "../../../custom-ui/input-fields/AutoSavePlainText";
import { applyNowMetric } from "../../../../callable-cloud-functions/cloudFunctions";
import { useUser } from "../../../../providers/database";

function Resume({
  userId,
  handleDone,
  jobData,
  children,
  deviceType,
}: {
  userId: string;
  handleDone: Function;
  jobData?: any;
  children?: React.ReactNode;
  deviceType?: string
}) {
  const [file, setFile] = useState();
  const [isUploaded, setIsUploaded] = useState();
  const [formAlert, setFormAlert] = useState(null);
  const [isSubmiting, setIsSubmitting] = useState(false)
  const [isfilesExist, setFilesExist] = useState(null)
  const { data: userData } = useUser(userId);
  const confirmUpload = async () => {
    if ((!file) && isfilesExist.length > 0) {
      setIsSubmitting(true)
      await applyNowMetric({ jobId: jobData?.id, userId: userId, platform: deviceType, solutionType: jobData?.solutionType === "job_board" ? "DH" : "BPO" });

      updateAutoSaveForResume(collectionNames.resume, userId, {
        companyName: jobData?.companyFullName,
        companyId: jobData?.companyId,
        jobTitle: jobData?.jobName,
        jobId: jobData?.id,
        applicantEmail: userData?.email,
        applicantName: userData?.name || userData?.displayName,
        applicantContactNo: userData?.phone,
        noATSContactEmail: jobData?.noATSContactEmail,
        noATS: jobData?.noATS
      }).then((data) => {
        setFile();
        setIsUploaded(true)
        handleDone();
        setIsSubmitting(false)
        toast.success("You have successfully applied for the job.")
      })
    } else {
      setIsSubmitting(true)
      upload(file, folderNames.resume, `${userId}_${file?.name}`).then(
        (fileUrl) => {
          writeResume(userId, {
            label: file?.name,
            type: file?.type,
            link: fileUrl,
          })
            .then((docRef) => {
              updateAutoSaveForResume(collectionNames.resume, userId, {
                companyName: jobData?.companyFullName,
                companyId: jobData?.companyId,
                jobTitle: jobData?.jobName,
                jobId: jobData?.id,
                applicantEmail: userData?.email,
                applicantName: userData?.name || userData?.displayName,
                applicantContactNo: userData?.phone,
                noATSContactEmail: jobData?.noATSContactEmail,
                noATS: jobData?.noATS
              })
              setFile();
              setIsUploaded(true)
              handleDone();
              setIsSubmitting(false)
              toast.success("You have successfully applied for the job.")
            })
            .catch((error: Error) => {
              setFormAlert({
                type: "error",
                message: friendlyFirebaseError(error),
              });
            })
            .catch((error: Error) => { });
        },
      );
    }

  };
  return (
    <>

      <div className="w-full ">
        {formAlert && (
          <span className="mb-4 text-red-600">{formAlert.message}</span>
        )}{" "}
        <div className="w-full text-base mt-4">
          <AutoSavePlainText
            value={""}
            rows={5}
            collection="Resumes"
            recId={userId}
            required={false}
            name="resume"
            display="Tell us about your interest in this position."
            placeholder={"Just fill out this section, upload your resume, and hit submit—you’ll be connected directly with this employer! Keep an eye on your email for more details about the position from the company."}
            readOnly={false}
          />
        </div>
        <div className="w-full text-center mb-2">
          <div className="my-4">
            <SeekerFiles setFilesExist={setFilesExist} id={userId} handleClose={handleDone} />
          </div>
          {!(isfilesExist?.length > 0) && (
            
            <>
              {
                file ? <div className="w-full bg-white shadow-md py-4 px-3 rounded-lg flex justify-between">
                  <p>
                    {file?.name}
                  </p>
                  <p className="text-red-500 text-[18px] px-3  cursor-pointer" onClick={()=>setFile(null) }>
                     Remove
                  </p>
                  
               </div>: <DragAndDropVariantB setFile={setFile} isUploaded={isUploaded} />
            }
            </>
          )}
        </div>
        <div className="flex flex-col justify-center items-center">
          <button
            onClick={confirmUpload}
            disabled={!(file || isfilesExist?.length > 0)}
            className={`px-14 mt-6 mb-6 py-4 rounded-full ${file || isfilesExist?.length > 0 ? "bg-instant-teams-blue-Main" : "bg-gray-400"
              } text-white flex items-center justify-center gap-2`}
          >
            {isSubmiting ? (
              <div className="flex items-center">
                <p>Submitting</p>
                <span className="inline-flex mt-1 ml-1">
                  <span className="w-1 h-1 bg-white rounded-full  animate-[dotWave_1.5s_infinite]"></span>
                  <span className="w-1 h-1 bg-white rounded-full animate-[dotWave_1.5s_infinite] delay-200 ml-1"></span>
                  <span className="w-1 h-1 bg-white rounded-full animate-[dotWave_1.5s_infinite] delay-400 ml-1"></span>
                </span>
              </div>
            ) : (
              "Submit"
            )}
          </button>

        </div>
      </div>

    </>

  );
}

export default Resume;
