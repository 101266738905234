



import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNotificationData, updateIsRead } from "../../data/notifications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { UserNotification } from "../../dataTypes/Notifications";
import { useGlobalState } from "../../providers/globalState/GlobalStateProvider";
import { useNavigate } from "react-router-dom";
import { useJobByIdForNewEmp } from "../../data/listings";

const Notification_NewUI = ({
    userNotification,
}) => {
    const [notification, setNotification] = useState({});
    const navigate = useNavigate();
    const { isRead, notificationId, userId } = userNotification;
    const [expandNotification, setExpandNotification] = useState(false);
    const { globalState, setGlobalState } = useGlobalState();
    const [isJobStateNotLive, setIsJobStateNotLive] = useState(false)

    const [jobData, setJobData] = useState();
    const { data } = useJobByIdForNewEmp(notification?.jobId);
    useEffect(() => {
        handleNotification(notificationId);
    }, [notificationId]);

    async function handleNotification(notificationId) {
        const notification = await getNotificationData(notificationId);
        setNotification(notification);
    }
    useEffect(() => {
        setJobData(data)
    }, [data])

    function handleClick() {
        updateIsRead(notificationId, userId);
        setExpandNotification(!expandNotification);
    }

    const handleClickNotification = () => {
        if (jobData?.jobState === "live") {
            setGlobalState(
                { ...globalState, searchKeyWords: jobData?.jobName }
            ); navigate(`/jobs/`)
            setIsJobStateNotLive(false)
        } else {
            setIsJobStateNotLive(true)
        }

    }

    return (
        <div
            className={`flex flex-col justify-center mouse-pointer relative mt-1 pl-2 pr-8  w-full bg-white text-black mb-2 overflow-hidden hover:overflow-visible hover:justify-start h-36 transition-all duration-500 ease-in-out hover:pt-2`}
            onClick={handleClick}
            onMouseEnter={() => setExpandNotification(true)}
            onMouseLeave={() => setExpandNotification(false)}
        >
            <div className="flex justify-between items-center">
                {/* If there is a notification url make the title clickable */}
                {notification?.url ? (
                    <div onClick={handleClickNotification} className="font-bold cursor-pointer text-instant-teams-blue-Main underline">
                        <Link to={notification?.url} target="_blank">
                            {notification?.title}
                        </Link>
                    </div>
                ) : (
                    <div onClick={handleClickNotification} className="font-bold cursor-pointer text-instant-teams-blue-Main text-sm">{notification?.title}</div>
                )}
                <div>
                    <p className="text-xs text-gray-600 pb-1">
                        {notification?.createdAt
                            ? DisplayTimestamp(notification?.createdAt)
                            : ""}
                    </p>
                </div>


            </div>

            <div
                onClick={handleClickNotification} className={` text-sm cursor-pointer text-gray-600 ${expandNotification ? "whitespace-normal overflow-clip" : "truncate"
                    }`}
            >
                {notification?.body}
            </div>
        </div>
    );
};

export default Notification_NewUI;
