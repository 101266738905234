import React, { useState, useEffect, useMemo } from 'react';
import JobsCards from './JobsCards/JobsCards';
import { useAllListingsJobForNew } from '../../../../../data/listings';
import { useParams, } from 'react-router-dom';
import JobsListingHeader from './SeekerFilter/JobsListingHeader';
import JobsDetails from './JobsCards/JobsDetails';
import JobsPagination from './JobsPagination/JobsPagination';
import { filterJobs, getMappedDataWithLookupCode, sortByFilter } from './util';
import { useAuth } from '../../../../../providers/auth';
import { requireAuth } from '../../../../../providers/auth';
import { useGlobalState } from '../../../../../providers/globalState/GlobalStateProvider';
import { getUserBookmarks } from '../../../../../data/user';
import { filterByLatestJobs } from './util';
import Meta from '../../../../custom-ui/meta/Meta';
import RotatorDisplay from '../../../../../pages/seekerRotator/parts/rotatorDisplay';
import { useLookup } from '../../../../../data/lookup';
import DialogWrapper from '../../../../custom-ui/dialog/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const Jobs = () => {
    const { companyId = "" } = useParams();
    const navigate = useNavigate()
    const { data, isLoading } = useAllListingsJobForNew();
    const userData = useAuth();
    const { globalState, setGlobalState } = useGlobalState();
    const [jobsData, setJobsData] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(10);
    const [bookmarks, setBookmarks] = useState([]);
    const [jobsDetailData, setJobsDetailData] = useState();
    const [sortedBy, setSortedBy] = useState("instanteamsJobs");
    const [selectedJobId, setSelectedJobId] = useState(null);
    const [isAddressFieldNotFilled, setIsAddressFieldNotFilled] = useState();

    const indexOfLastJob = currentPage * jobsPerPage;
    const indexOfFirstJob = indexOfLastJob - jobsPerPage;


    const currentJobs = useMemo(() => {
        return filteredJobs?.slice(indexOfFirstJob, indexOfLastJob);
    }, [filteredJobs, indexOfFirstJob, indexOfLastJob]);

    const totalPages = useMemo(() => {
        return Math.ceil(filteredJobs?.length / jobsPerPage);
    }, [filteredJobs, jobsPerPage]);



    const bookmarksData = getUserBookmarks(userData?.user?.id);

    const { data: aspectsOfJob = [] } = useLookup("aspectsOfJob");
    const { data: aboutThisJob = [] } = useLookup("aboutThisJob");
    const { data: mustHaves = [] } = useLookup("mustHaves");

    const aspectsOfJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aspectsOfJob), [aspectsOfJob]);
    const aboutThisJobMappedWithCode = useMemo(() => getMappedDataWithLookupCode(aboutThisJob), [aboutThisJob]);
    const mustHavesMappedWithCode = useMemo(() => getMappedDataWithLookupCode(mustHaves), [mustHaves]);

    const setStartIndex = filteredJobs.length < indexOfFirstJob

    useEffect(() => {
        setCurrentPage(1)
        setJobsPerPage(10)
    }, [setStartIndex])

    useEffect(() => {
        setBookmarks(bookmarksData);
        if (!userData?.user?.seekerLocationInfo?.stateResidence ) {
            setIsAddressFieldNotFilled(true)
        }   
    }, [userData]);
   
    useEffect(() => {
        const _filterByKeyword = filterJobs(jobsData, globalState?.searchKeyWords);
        const _sortedBylatest = filterByLatestJobs(_filterByKeyword);
        setGlobalState({ ...globalState, filteredJobs: _sortedBylatest });
        setFilteredJobs(_sortedBylatest);
    }, [globalState?.searchKeyWords]);

    useEffect(() => {
        sortByFilter(filteredJobs, sortedBy, setFilteredJobs);
    }, [sortedBy]);

    useEffect(() => {
        sortByFilter(filteredJobs, sortedBy, setFilteredJobs);
    }, [jobsData]);



    useEffect(() => {
        if (data) {
            if (globalState?.searchKeyWords) {
                const byCompanyJob = data.filter((data) => {
                    if (companyId) {
                        if (globalState.jobType === "bpo" || globalState?.searchKeyWords === "Instant Teams") {
                            return data.solutionType === "bpo" || data.companyId === companyId;
                        } else {
                            return data.companyId === companyId;
                        }
                    }
                    return true;
                });
                setJobsData(data);
                const _filterByKeyword = filterJobs(
                    globalState?.searchKeyWords === "Instant Teams" ? [...byCompanyJob] : byCompanyJob,
                    globalState?.searchKeyWords
                );
                const _sortedBylatest = filterByLatestJobs(_filterByKeyword);
                setGlobalState({ ...globalState, filteredJobs: _sortedBylatest });
                setFilteredJobs(_sortedBylatest);
            } else {
                setJobsData(data);
                const sortedBylatest = filterByLatestJobs(data);
                setFilteredJobs(sortedBylatest);
            }
        }
    }, [data]);

    useEffect(() => {
        setJobsDetailData(filteredJobs[0]);
        if (!selectedJobId) {
            // Executes only when selectedJobId doesn't exist
            if (filteredJobs) {
                setJobsDetailData(filteredJobs[0]);
                setSelectedJobId(filteredJobs[0]?.id);
                setCurrentPage(1);
            } else {
                setJobsDetailData(null);
            }
        }
    }, [filteredJobs]);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleResultsPerPageChange = (e) => {
        setJobsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };

    return (
        <>

            <div className="w-full h-full">
                <Meta title="Jobs" />
                {jobsData.length > 0 ? (
                    <div>
                        <div className="grid table-fixed mt-36 pt-4 xs:grid-cols-1 md:grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 ">
                            <div className="bg-gray-100 px-7 py-4 col-span-1 flex flex-col">
                                <JobsListingHeader setFilteredJobs={setFilteredJobs} bookmarks={bookmarks} filteredJobs={filteredJobs} jobsData={jobsData} setSortedBy={setSortedBy} totalLiveJobs={filteredJobs?.length} />
                                <div style={{ scrollbarWidth: 'thin', scrollbarColor: '#b3b3b3 #f0f0f0' }} className="flex-grow overflow-y-auto">
                                    <div className='w-full mt-2' style={{ height: 'calc(100vh - 300px)' }}>
                                        <div className='w-full bg-gray-100'>
                                            {currentJobs?.length > 0 ? (
                                                currentJobs?.map((job) => (
                                                    <JobsCards
                                                        key={job.id}
                                                        jobsData={job}
                                                        setBookmarks={setBookmarks}
                                                        bookmarks={bookmarks}
                                                        userData={userData}
                                                        setJobsDetailData={setJobsDetailData}
                                                        setSelectedJobId={setSelectedJobId} // Pass the function
                                                        selectedJobId={selectedJobId}
                                                        jobsDetailData={jobsDetailData}
                                                        aspectsOfJobMappedWithCode={aspectsOfJobMappedWithCode}
                                                        aboutThisJobMappedWithCode={aboutThisJobMappedWithCode}
                                                        mustHavesMappedWithCode={mustHavesMappedWithCode}// Pass the selected ID
                                                    />
                                                ))
                                            ) : (
                                                <div className='w-full px-4 bg-white flex justify-center items-center h-40 shadow-xl rounded-xl'>
                                                    <p className="text-center text-gray-500">No results found</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <JobsPagination
                                        handleResultsPerPageChange={handleResultsPerPageChange}
                                        paginate={paginate}
                                        currentPage={currentPage}
                                        jobsPerPage={jobsPerPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                            </div>

                            <div className="col-span-2 xs:hidden md:hidden h-[80vh] pr-4 pb-4 bg-gray-100">
                                <div style={{ height: "calc(100vh - 200px)" }} className="w-full h-full">
                                    <JobsDetails
                                        bookmarks={bookmarks}
                                        setBookmarks={setBookmarks}
                                        jobsDetailData={jobsDetailData}
                                        aspectsOfJobMappedWithCode={aspectsOfJobMappedWithCode}
                                        aboutThisJobMappedWithCode={aboutThisJobMappedWithCode}
                                        mustHavesMappedWithCode={mustHavesMappedWithCode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (

                    <div className="w-[100vw] h-[100vh] flex  justify-center items-center">
                        {!isLoading ? <div className='flex text-3xl w-[100vw] mt-[100vh] text-gray-500 justify-center items-center '>
                            Currently there are no jobs available
                        </div> : <div className="w-[100vw] h-[100vh] flex  justify-center items-center">
                            <img className='w-24 h-24' src="/images/loader_v2.gif" alt="loader" />
                        </div>
                        }
                    </div>
                )
                }
            </div>

            {isAddressFieldNotFilled && <DialogWrapper
                title=''
                onClose={() => null}
            >
                <>
                    <div className="w-full sticky top-0 bg-white z-10 px-4 py-3 flex justify-end items-center ">
                        <div
                            className="flex justify-center items-center w-7 h-7 mt-[-10px] bg-gray-200 rounded-full cursor-pointer"
                            onClick={() => setIsAddressFieldNotFilled(false)}
                        >
                            <FontAwesomeIcon icon={faTimes} color="#565656" />
                        </div>
                    </div>

                    <div className=' flex flex-col items-center justify-center'>
                        <p className='text-gray-500  text-center  text-xl'>Please update your State, City and <br /> Zip Code information within your <br /> <strong>{`My Profile > Account Settings.`}</strong> </p>
                        <button onClick={() => navigate("/settings/seeker/profile")} className="bg-instant-teams-blue-Main text-white py-[16px] px-[34px] mt-10 rounded-full flex items-center text-[16px]">
                            Account Settings
                        </button>
                    </div>
                </>
            </DialogWrapper>}
        </>

    );
};

export default requireAuth(Jobs);
