import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Meta from "../../components/custom-ui/meta/Meta";
import { requireAuth } from "../../providers/auth";
import {
  useUserNotificationIds,
  updateAllIsRead,
  deleteAllNotifications,
} from "../../data/notifications";
import { useAuth } from "../../providers/auth";
import UserNotificationItem from "../../components/notifications/UserNotificationItem_NewUI";
import GreenUnderlineHeading from "../../components/custom-ui/headings/heading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner";


const NotificationsPage_NewUI = ({ headerTitle }) => {
  const navigate = useNavigate();
  const params = useParams();
  const user = useAuth();
  const {
    data: userNotificationList,
    status: notificationsStatus,
    error: notificationsError,
  } = useUserNotificationIds(user.user.id);
  const [loading, setLoading] = useState(false)



  function handleAllRead() {
    updateAllIsRead(params.userId);
  }

  const handleDeleteAllNotifications = () => {
    setLoading(true)
    deleteAllNotifications(params.userId).then(() => setLoading(false))
  }



  return (
    <div className=" mx-36 xs:mx-1 md:mx-1  pt-40 md:pt-44 xs:pt-44 px-36 xs:p-2 md:p-2 min-h-screen">
      <div onClick={() => navigate("/jobs")} className="w-[4%] ml-[-20%] mt-[-5%] cursor-pointer ">
        <p className="py-2  text-white px-5 rounded-lg">        <FontAwesomeIcon icon={faArrowLeft} className="text-3xl text-instant-teams-blue-Main font-thin" />
        </p>

      </div>
      <Meta title="My Notifications" />

      <div className="flex w-full justify-between items-center py-6">

        <div>
          <h1 className="text-2xl cursor-pointer text-instant-teams-blue-Main font-bold">
            Notifications
          </h1>
       
        </div>

        <div className="ml-auto mr-0 flex justify-center items-center ">
        <div onClick={handleDeleteAllNotifications} className="text-base mr-5 cursor-pointer text-instant-teams-blue-Main underline ">
            {loading ? (<div className=" mt-[-10px] " > 
              <div className="w-4 h-4 mr-20">
                   <LoadingSpinner />
              </div>
           
            </div>) : "Clear All Notifications"}
          </div>
          <button
            className="bg-instant-teams-blue-Main px-6 mt-0 rounded-full py-3 p-2 text-white"
            onClick={handleAllRead}
          >
            Mark All Read
          </button>

        
        </div>

      </div>

      <table className="w-full">
        {userNotificationList &&
          userNotificationList?.length > 0 ?
          userNotificationList?.slice(0, 20).map((userNotification) => (
            <UserNotificationItem
              userNotification={userNotification}
              key={userNotification.notificationId}
            />
          )):(<div className=" flex w-full justify-center mt-10"> <p className="text-xl text-gray-600">No unread notifications</p></div>)}
      </table>
    </div>
  );
};

export default requireAuth(NotificationsPage_NewUI);
