import React, { useEffect, useState } from "react";
import {
    getNotificationData,
    updateAllIsRead,
    updateIsRead,
} from "../../data/notifications";
import { DisplayTimestamp } from "../../util/timestamps/timestamp";
import { UserNotification } from "../../dataTypes/Notifications";
import { TailwindHoverEnlarge } from "../../util/tailwind/tailwind-styles";
import { useNavigate } from "react-router-dom";
import { useJobByIdForNewEmp } from "../../data/listings";
import { useGlobalState } from "../../providers/globalState/GlobalStateProvider";
import ToastAlert from "../custom-ui/toast-alert/ToastAlert";
import toast from "react-hot-toast";

const UserNotificationItem_NewUI = ({
    userNotification,
}) => {
    const [notification, setNotification] = useState({});
    const { isRead, notificationId, userId, readAt } = userNotification;
    const [jobData, setJobData] = useState();

    const navigate = useNavigate();
    const { globalState, setGlobalState } = useGlobalState();

    useEffect(() => {
        handleNotification(notificationId);
    }, [notificationId]);

    async function handleNotification(notificationId) {
        const notification = await getNotificationData(notificationId);
        console.log("Notification Body %BODY", notification)
        setNotification(notification);
    }

    function handleClick() {
        updateIsRead(notificationId, userId);
    }

    const { data } = useJobByIdForNewEmp(notification?.jobId);

    useEffect(() => {
        setJobData(data)

    }, [data])


    const handleClickNotification = () => {
        if (jobData?.jobState === "live") {
            setGlobalState(
                { ...globalState, searchKeyWords: jobData?.jobName }
            ); navigate(`/jobs/`)

        } else {
            toast.error("Currently, this job is no longer accepting applications. Browse our job listings to find your next opportunity.")
        }

    }


    return (
        <>
            <tr
                className={
                    TailwindHoverEnlarge(103) + "pl-5 border-b pb-3  border-instant-teams-teal-L3 h-auto"
                }
            >
                <td onClick={handleClickNotification} className="w-1/2 cursor-pointer py-6 text-instant-teams-blue-Main px-4  ">
                    <strong>{notification?.title}</strong>
                    <br />
                    <p className="text-gray-500 text-[16px] mt-1">
                        {notification?.body}
                    </p>



                </td>

                <td className="w-1/4 px-4  text-right">
                    {!isRead ? (
                        <>
                            <button
                                className="border-[1px] xs:text-sm md:text-sm border-instant-teams-blue-Main rounded-full p-2 px-5 xs:px-3 md:px-3 text-instant-teams-blue-Main"
                                onClick={handleClick}
                            >
                                Mark Read
                            </button>
                        </>
                    ) : (
                        <p className="text-gray-500 text-sm">
                            {DisplayTimestamp(readAt)}
                        </p>

                    )}
                </td>
            </tr>
            <ToastAlert/>
        </>
    );
};

export default UserNotificationItem_NewUI;
